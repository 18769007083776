import React, { useEffect } from "react";
import Navbar from "../../../components/common/navbar/Navbar";
import HeaderWithProfile from "../../../components/headerWithProfile";
import { Table, Layout, Card, Row, Col, Button } from 'antd';
import { Content } from "antd/lib/layout/layout";
import "./viewPatient.css";
import { getPatientDetails } from "../../../stores/actions/patient";
import { patientDetailsSelector } from "../../../stores/selectors/patient";
import { useDispatch, useSelector } from 'react-redux';


const ViewPatient = (props) => {

    const calling_number = props.match.params.calling_number;
    console.log(calling_number);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPatientDetails(calling_number));
    }, [dispatch, calling_number]);

    const patientDetails = useSelector((state) => patientDetailsSelector(state));
    console.log(patientDetails);

    const basicInfoColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            responsive: ['lg'],
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            responsive: ['lg'],
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            responsive: ['lg'],
        },
        {
            title: 'Calling Number',
            dataIndex: 'callingNumber',
            key: 'callingNumber',
            responsive: ['lg'],
        },
        {
            title: 'Whatsapp Number',
            dataIndex: 'whatsappNumber',
            key: 'whatsappNumber',
            responsive: ['lg'],
        },
    ];

    const basicInfoData = [
        {
            key: '1',
            name: patientDetails.patientInfo.name,
            gender: patientDetails.patientInfo.gender,
            height: patientDetails.patientInfo.height,
            weight: patientDetails.patientInfo.weight,
            callingNumber: patientDetails.patientInfo.calling_number,
            whatsappNumber: patientDetails.patientInfo.whatsapp_number,
        },
    ];

    const activePlanColumns = [
        {
            title: 'Plan Name',
            dataIndex: 'planName',
            key: 'planName',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            responsive: ['lg'],
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            responsive: ['lg'],
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            responsive: ['lg'],
        }
    ];

    const activePlanData = patientDetails.activePlan.length > 0 ? patientDetails.activePlan.map((plan, index) => {
        return {
            key: index,
            planName: plan.planName,
            status: plan.planStatus ? "Active" : "Expired",
            startDate: plan.planStartDate,
            endDate: plan.planEndDate,
        }
    }) : [];

    return (
        <Layout>
            <Navbar defaultIndex={2} />

            <Layout>
                <Content>
                    <HeaderWithProfile title={"View Patient"} />

                    <div className="patient-image-name">
                        <div className="patient-image">
                        </div>
                        <span className="patient-name">{patientDetails.patientInfo.name}</span>
                    </div>

                    <div className="basic-info">
                        <span className="basic-info-heading">Basic Info</span>

                        <div className="basic-info-table">
                            <Table columns={basicInfoColumns} dataSource={basicInfoData} pagination={false} />
                        </div>
                    </div>

                    <div className="active-plan">
                        <span className="active-plan-heading">Active Plan</span>

                        <div className="active-plan-table">
                            <Table columns={activePlanColumns} dataSource={activePlanData} pagination={false} />
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default ViewPatient;