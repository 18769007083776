import { Table, Layout, Card, Row, Col, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import Navbar from '../../components/common/navbar/Navbar';
import HeaderWithProfile from "../../components/headerWithProfile";
import './prescription.css';
import { EditOutlined, FileTextOutlined } from '@ant-design/icons';
import { Heading2, Heading3, Heading4, Heading5 } from "../../components/elements/text";
import { getPrescriptions } from '../../stores/actions/prescription';
import { prescriptionListSelector } from '../../stores/selectors/prescription';
import { useDispatch, useSelector } from 'react-redux';


const Prescription = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPrescriptions());
    }, [dispatch]);

    const prescriptions = useSelector((state) => prescriptionListSelector(state));
    console.log(prescriptions);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            responsive: ['lg'],
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            responsive: ['lg'],
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            responsive: ['lg'],
        },
        {
            title: 'Prescription Link',
            dataIndex: 'prescriptionLink',
            key: 'prescriptionLink',
            render: (link) => <a href={link} target="_blank" style={{ color: "#DC4A9A" }}> Open <span><FileTextOutlined style={{ color: "#DC4A9A", position: "absolute", bottom: "36.36%", marginLeft: "5px" }} /></span></a>,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (calling_number) => <a onClick={() => { history.push(`/viewPatient/${calling_number}`) }} style={{ color: "#DC4A9A" }}>View Patient</a>,
            responsive: ['lg'],
        },
    ];
    const data = prescriptions.length !== 0 ? prescriptions.map((prescription, index) => ({
        key: index,
        date: prescription.created_at ? prescription.created_at : "N/A",
        name: prescription.name ? prescription.name : "N/A",
        age: prescription.age ? prescription.age : "N/A",
        gender: prescription.gender ? prescription.gender : "N/A",
        phoneNumber: prescription.calling_number ? prescription.calling_number : "N/A",
        prescriptionLink: prescription.prescription_url,
        action: prescription.calling_number,
    })) : [];

    return (
        <Layout style={{ backgroundColor: "#fff" }}>
            <Navbar defaultIndex={3} />

            <Layout>
                <Content>
                    <HeaderWithProfile title={"Prescriptions"} />
                    <div className='prescription-container'>

                        <div className='overview'>
                            <div style={{ justifyContent: "space-between", margin: "auto", display: "flex", flexDirection: "row" }}>
                                <Col span={12}>
                                    <Heading3 weight="bold" color="oxfordBlue" text="Overview" />
                                    {/* <h1 style={{fontFamily: 'Montserrat', fontSize: "20px", fontWeight: 600, color: "#10202D"}}></h1> */}
                                </Col>
                                <Col>
                                    <Button className='add-patient-btn'><span><EditOutlined style={{ color: "#ffff", marginRight: "5px" }} /></span> Write Prescription</Button>
                                </Col>
                            </div>

                            <div className="prescription-overview-card-container">
                                <Card
                                    className="prescription-overview-card"
                                    width={100}
                                    style={{ borderRadius: "12px", backgroundColor: "rgba(255, 241, 230, 0.5)" }}
                                >
                                    <div className="overview-card-text-container" >
                                        <div className="overview-card-text">Total Prescriptions</div>
                                        <div class="tooltip">
                                            <img src="/images/info-icon.svg" alt="info-icon" />
                                            <span class="tooltiptext">Tooltip text</span>
                                        </div>
                                    </div>
                                    <div className="home-overview">
                                        <img className='icon' src="/images/dollar-circle.svg" alt="Refer Patient" />
                                        <span className="overview-card-number" style={{ color: "#A0644A" }}>32</span>
                                    </div>

                                </Card>
                            </div>
                        </div>
                        <div className="patient-table">
                            <Table columns={columns} dataSource={data} />;
                        </div>
                    </div>
                </Content>
            </Layout>


        </Layout>
    );
};

export default Prescription;