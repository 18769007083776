import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { ROOT_URL } from "../../config";
import UviLogo from "../common/uviLogo";
import ProfileCompletion from "../common/profileCompletionComp";
import { Heading2, Body2 } from "../elements/text";
import { Menu, Button, Card } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { setExpert } from "../../stores/actions/expert";
import { useSelector, useDispatch } from "react-redux";
import { expertDataSelector } from "../../stores/selectors/expert";

import "./HeaderWithProfile.css";

const HeaderWithProfile = ({ isHome, title }) => {

  const history = useHistory();

  const navList = [["All Patient", '/patients'], ["Prescriptions", '/prescriptions'], ["Successful Referrals", '/successfulReferrals'], ["Support", '/support']]
  const [cardShow, setCardShow] = useState(false);

  const dispatch = useDispatch();
  const [expertData, setExpertData] = useState({})
  const _expertData = useSelector((state) => expertDataSelector(state));

  useEffect(() => {
    const getData = async () => {
      const resp = await axios.get(ROOT_URL + "/profile", {
        withCredentials: true,
        credentials: "include",
        params: null,
      });
      if (resp && resp.data && resp.data.responseData)
        dispatch(setExpert(resp.data.responseData[0]));
    };
    getData();
  }, []);

  useEffect(() => {
    if (_expertData && _expertData.name) {
      setExpertData(_expertData)
    }
  }, [_expertData])

  return (
    <div className="header_with_profile-wrapper">
      <div className="header_with_profile-container">
        <div  style={{ marginTop: 15 }}>
          <div className="header_with_profile-desktopOnly">
          {isHome ? <Heading2 weight="bold" color="legend" text=" Welcome," /> : null}
          </div>
          
          <div className="doctor-name">

            <Heading2 weight="bold" color="legend" text={title} />
          </div>

          <div className="uvi-logo">
            <UviLogo />
          </div>

        </div>
        <div className="profile-complition-mobile-only">
          <ProfileCompletion expertData={expertData} />
        </div>
      </div>
      <div className="header_with_profile-name_container">

        <Heading2 dynamic={true} weight="bold" color="legend" text={title} />

        <Button onClick={() => setCardShow(!cardShow)} icon={<MenuOutlined />} >
        </Button>

      </div>
      <div className="header_with_navbar">
        <Card
          style={{
            width: 380,
            marginLeft: "auto",
            marginTop: 20,
            display: cardShow ? "block" : "none",
            borderRadius: 20,
          }}
        >
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <a onClick={(e) => { history.push("/") }} style={{ color: "rgb(39, 80, 112)", fontWeight: 500, fontSize: 18, padding: "20px" }}>Home</a>
            <br />
            {
              navList.map(
                (item) =>
                  <div style={{ marginTop: 20 }}>
                    <a onClick={(e) => { history.push(item[1]) }} style={{ color: "rgb(39, 80, 112)", fontWeight: 500, fontSize: 18, padding: "20px" }}>{item[0]}</a>
                    <br />
                  </div>
              )
            }
          </div>
        </Card>
      </div>
    </div>
  );
};

export default HeaderWithProfile;
